.bottomChartContainer1 {
  margin-top: 32px;
}

.bottomChartContainer2 {
  margin-top: 48px;
}

.bottomContainer {
  background-color: var(--color-highlight);
  padding-bottom: 54px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 42px;
  position: relative;
  z-index: 1;
}

.bottomTitle {
  color: var(--color-white);
  font-family: var(--dm-sans), sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  margin: auto;
  text-align: center;
}

.charts {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 42px;
}

.reviewStats {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 72px;
}

.reviewStatsHarken {
  margin-top: 32px;
}

.squigglyBottom {
  background-image: url("/images/landing/squiggly-bottom-small.svg");
  bottom: -6px;
  height: 6px;
  left: 0px;
  position: absolute;
  width: 100%;
}

.squigglyTop {
  background-image: url("/images/landing/squiggly-top-small.svg");
  height: 6px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}

.timelineSection1 {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.timelineSection2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 80px;
}

.timelineTitle {
  color: var(--color-white);
  font-family: var(--work-sans), sans-serif;
  font-weight: 400;
  margin-bottom: 24px;
  max-width: 282px;
  text-align: center;
}

.topContainer {
  background-color: var(--color-lightPurple);
  padding-bottom: 48px;
  padding-top: 36px;
}

.topContainerInner {
  box-sizing: border-box;
  margin: auto;
  max-width: 1600px;
  padding: 0px 24px;
}

.topDescription {
  color: var(--color-primary);
  font-family: var(--dm-sans), sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  margin-top: 12px;
  max-width: 1009px;
}

.topExplanation {
  color: var(--color-primary);
  font-family: var(--work-sans), sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 12px;
}

.topTitle {
  font-family: var(--work-sans), sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 */
@media only screen and (min-width: 640px) {
  .topContainerInner {
    padding: 0px 48px;
  }

  .topTitle {
    font-size: 16px;
  }
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 */
@media only screen and (min-width: 768px) {
  .bottomChartContainer1 {
    margin-top: 48px;
  }

  .bottomChartContainer2 {
    margin-top: 60px;
  }

  .bottomContainer {
    padding: 80px 48px;
  }

  .bottomTitle {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }

  .squigglyBottom {
    background-image: url("/images/landing/squiggly-bottom-large.svg");
    bottom: -19px;
    height: 19px;
  }

  .squigglyTop {
    background-image: url("/images/landing/squiggly-top-large.svg");
    height: 15px;
  }

  .topContainer {
    padding-top: 60px;
  }

  .topDescription {
    font-size: 36px;
    line-height: 46px;
    margin-top: 32px;
  }

  .topExplanation {
    font-family: var(--work-sans), sans-serif;
    font-size: 20px;
    line-height: 26px;
    margin-top: 16px;
  }

  .topTitle {
    font-size: 20px;
  }
}

/* Matches HomeTimeline responsiveness */
@media only screen and (min-width: 800px) {
  .charts {
    margin-top: 60px;
  }

  .reviewStats {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 72px;
  }

  .reviewStatsHarken {
    margin-left: 48px;
    margin-top: 0;
  }

  .timelineTitle {
    font-size: 16px;
    margin-bottom: 30px;
    max-width: 400px;
  }

  .timelineSection2 {
    margin-top: 90px;
  }
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 */
@media only screen and (min-width: 1024px) {
  .bottomChartContainer1 {
    margin-top: 0px;
  }

  .bottomChartContainer2 {
    margin-top: 0px;
  }

  .bottomContainer {
    padding: 100px 48px;
  }

  .charts {
    margin: auto;
    margin-top: 52px;
    max-width: 950px;
  }

  .topContainer {
    padding-bottom: 72px;
    padding-top: 72px;
  }
}

/* Matches HomeTimeline responsiveness */
@media only screen and (min-width: 1200px) {
  .reviewStats {
    margin-top: 88px;
  }

  .timelineTitle {
    font-size: 18px;
    max-width: 900px;
  }
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 */
@media only screen and (min-width: 1280px) {
  .topContainerInner {
    padding: 0px 120px;
  }
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 */
@media only screen and (min-width: 1536px) {
  .topContainerInner {
    padding: 0px 220px;
  }
}
