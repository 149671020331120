.contact {
  color: var(--color-white);
  font-family: var(--work-sans), sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.contact:hover {
  color: #9597c2;
}

.container {
  background-color: #171830;
}

.copyright {
  color: white;
  font-family: var(--work-sans), sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin-top: 8px;
}

.getInTouchSection {
  margin-top: 32px;
}

.getInTouchTitle {
  color: #a0a3e4;
}

.innerContainer {
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 1600px;
  padding: 36px 36px;
  position: relative;
}

.logoAndCopyrightSection {
  margin-top: 32px;
}

.resourcesItem {
  color: var(--color-white);
  display: block;
  font-family: var(--work-sans), sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-top: 12px;
  text-decoration: none;
}

.resourcesItem:hover {
  cursor: pointer;
}

.resourcesTitle {
  color: #a0a3e4;
}

.sokiLogo {
  display: block;
  height: 33px;
  width: 102px;
}

.terms {
  margin-top: 8px;
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 */
@media only screen and (min-width: 640px) {
  .getInTouchSection {
    margin-left: 64px;
    margin-top: 0;
  }

  .innerContainer {
    flex-direction: row;
    padding: 48px 48px;
  }

  .logoAndCopyrightSection {
    margin-top: 0;
    order: -1;
  }

  .resourcesSection {
    margin-left: 64px;
  }
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 */
@media only screen and (min-width: 768px) {
  .contact {
    font-size: 16px;
  }

  .copyright {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }

  .sokiLogo {
    height: 51px;
    width: 160px;
  }
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 */
@media only screen and (min-width: 1280px) {
  .innerContainer {
    padding: 60px 120px;
  }
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 */
@media only screen and (min-width: 1536px) {
  .innerContainer {
    padding: 60px 220px;
  }
}
