.container {
  border: 1px solid var(--color-separatorWithShadow);
  border-radius: 36px;
  box-shadow: 0px 0px 60px rgba(171, 171, 171, 0.4);
  height: 339px;
  overflow: hidden;
  width: 327px;
}

.description {
  color: var(--color-primary);
  font-family: var(--work-sans), sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.41px;
  line-height: 22px;
  margin-top: 6px;
}

.image {
  width: 100%;
}

.subtitle {
  font-family: var(--work-sans), sans-serif;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.textSection {
  padding: 18px 20px;
}

.title {
  color: var(--color-primary);
  font-family: var(--dm-sans), sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.41px;
  line-height: 28px;
  margin-top: 7px;
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 */
@media only screen and (min-width: 1280px) {
  .container {
    height: 365px;
    width: 500px;
  }

  .textSection {
    padding: 24px 26px 26px 26px;
  }
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 */
@media only screen and (min-height: 1280px) and (min-width: 1536px) {
  .container {
    height: 404px;
    width: 515px;
  }

  .description {
    font-size: 16px;
    line-height: 22px;
    margin-top: 12px;
  }

  .subtitle {
    font-size: 14px;
  }

  .textSection {
    padding: 30px 36px 32px 36px;
  }

  .title {
    font-size: 24px;
    line-height: 30px;
    margin-top: 12px;
  }
}
