.cardFrontBack {
  align-items: center;
  box-sizing: border-box;
  color: var(--color-primary);
  display: flex;
  font-family: "Work Sans", sans-serif;
  font-size: 24px;
  font-weight: 400;
  height: 50%;
  justify-content: center;
  line-height: 30px;
  padding: 40px;
  text-align: center;
}

.cardPreview {
  border-radius: 8px;
  box-shadow: var(--box-shadow-button);
  height: 374px;
  position: relative;
  width: 269px;
}

.container {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 36px;
}

.hr {
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-top: 2px dashed #e6e8f0;
  left: 0px;
  margin: 0px;
  position: absolute;
  top: 50%;
  transform: translateY(-1px);
  width: 100%;
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 * 
 * At this breakpoint, the card example is displayed in a phone.
 */
@media only screen and (min-width: 1024px) {
  .container {
    margin-bottom: 0px;
  }
}
