.container {
  box-sizing: border-box;
  margin: auto;
  max-width: 1600px;
  padding-bottom: 48px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 36px;
  position: relative;
}

.description {
  color: var(--color-primary);
  font-family: var(--dm-sans), sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  margin-top: 12px;
}

.featureCards {
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  justify-items: center;
  margin-top: 32px;
  row-gap: 32px;
}

.titleFont {
  font-family: var(--work-sans), sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 */
@media only screen and (min-width: 640px) {
  .container {
    padding-left: 48px;
    padding-right: 48px;
  }

  .featureCards {
    row-gap: 32px;
  }
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 */
@media only screen and (min-width: 768px) {
  .container {
    padding-bottom: 80px;
    padding-top: 80px;
  }

  .description {
    font-size: 36px;
    line-height: 46px;
    margin-top: 32px;
  }

  .featureCards {
    column-gap: 32px;
    grid-template-columns: auto auto;
    margin-top: 64px;
  }

  .titleFont {
    font-size: 20px;
  }
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 */
@media only screen and (min-width: 1024px) {
  .featureCards {
    column-gap: 44px;
    row-gap: 44px;
  }
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 */
@media only screen and (min-width: 1280px) {
  .container {
    padding-left: 120px;
    padding-right: 120px;
  }
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 */
@media only screen and (min-width: 1536px) {
  .container {
    padding-left: 220px;
    padding-right: 220px;
  }
}
