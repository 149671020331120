.container {
  background-color: var(--color-white);
  margin-top: 36px;
}

.header {
  margin-right: 24px;
}

.innerContainer {
  margin: auto;
  max-width: 1600px;
  position: relative;
}

.margins {
  margin-left: 24px;
  margin-right: 24px;
}

.subheader {
  color: var(--color-primary);
  font-family: var(--work-sans), sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-right: 24px;
  margin-top: 16px;
  max-width: 800px;
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 * 
 * Adjust left margin.
 */
@media only screen and (min-width: 640px) {
  .margins {
    margin-left: 48px;
    margin-right: 48px;
  }
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 * 
 * At this breakpoint, sizes are increased.
 */
@media only screen and (min-width: 768px) {
  .container {
    margin-top: 60px;
  }

  .subheader {
    font-size: 20px;
    line-height: 28px;
  }
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 * 
 * At this breakpoint, buttons switch to a vertical layout (instead of an h-scroll), and are placed
 * to the left of the card example.
 */
@media only screen and (min-width: 1024px) {
  .buttonsAndExamples {
    display: flex;
    justify-content: space-between;
    padding-right: 48px;
  }

  .container {
    margin-top: 72px;
  }
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 * 
 * Adjust margins and padding.
 */
@media only screen and (min-width: 1280px) {
  .buttonsAndExamples {
    padding-right: 90px;
  }

  .margins {
    margin-left: 120px;
    margin-right: 120px;
  }
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 * 
 * Adjust margins and padding.
 */
@media only screen and (min-width: 1536px) {
  .buttonsAndExamples {
    padding-right: 140px;
  }

  .margins {
    margin-left: 220px;
    margin-right: 220px;
  }
}
