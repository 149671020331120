.container {
  position: relative;
  width: 324px;
}

.label {
  color: var(--color-white);
  font-family: var(--work-sans), sans-serif;
  font-size: 10px;
  font-weight: 500;
  /* Half of point width */
  margin-left: 8.5px;
  position: absolute;
  text-align: center;
  top: 30px;
  text-transform: uppercase;
  transform: translateX(-50%);
}

.labelNumber {
  font-size: 16px;
}

.point {
  background-color: #9ea2ff;
  border: 3px solid var(--color-white);
  border-radius: 9.5px;
  box-sizing: border-box;
  height: 19px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 19px;
}

.timeline {
  background-image: url("/images/landing/timeline-small.svg");
  background-position: center;
  background-repeat: no-repeat;
  height: 23px;
  width: 324px;
}

/*
 * Harken styles
 */
.harkenContainer .point:first-child,
.harkenContainer .label:first-child {
  left: 0;
}

.harkenContainer .point:nth-child(2),
.harkenContainer .label:nth-child(2) {
  left: 52px;
}

.harkenContainer .point:nth-child(3),
.harkenContainer .label:nth-child(3) {
  left: 208px;
}

.harkenContainer .point:nth-child(n + 4),
.harkenContainer .label:nth-child(n + 4) {
  display: none;
}

/*
 * Traditional styles
 */
.traditionalContainer .point:first-child,
.traditionalContainer .label:first-child {
  left: 0;
}

.traditionalContainer .point:nth-child(2),
.traditionalContainer .label:nth-child(2) {
  left: 52px;
}

.traditionalContainer .point:nth-child(3),
.traditionalContainer .label:nth-child(3) {
  left: 104px;
}

.traditionalContainer .point:nth-child(4),
.traditionalContainer .label:nth-child(4) {
  left: 156px;
}

.traditionalContainer .point:nth-child(5),
.traditionalContainer .label:nth-child(5) {
  left: 208px;
}

.traditionalContainer .point:nth-child(6),
.traditionalContainer .label:nth-child(6) {
  left: 260px;
}

.traditionalContainer .point:nth-child(n + 7),
.traditionalContainer .label:nth-child(n + 7) {
  display: none;
}

@media only screen and (min-width: 800px) {
  .container {
    width: 704px;
  }

  .label {
    font-size: 12px;
    font-weight: 500;
    margin-left: 14.5px;
    top: 40px;
  }

  .label br {
    display: none;
  }

  .labelNumber {
    font-size: 12px;
  }

  .labelWeek::after {
    content: " ";
  }

  .point {
    border-radius: 14.5px;
    height: 29px;
    top: 0.5px;
    width: 29px;
  }

  .timeline {
    background-image: url("/images/landing/timeline-medium.svg");
    height: 30px;
    width: 704px;
  }

  /*
  * Harken styles
  */
  .harkenContainer .point:first-child,
  .harkenContainer .label:first-child {
    left: 0;
  }

  .harkenContainer .point:nth-child(2),
  .harkenContainer .label:nth-child(2) {
    left: 103px;
  }

  .harkenContainer .point:nth-child(3),
  .harkenContainer .label:nth-child(3) {
    left: 309px;
  }

  .harkenContainer .point:nth-child(4),
  .harkenContainer .label:nth-child(4) {
    display: initial;
    left: 618px;
  }

  .harkenContainer .point:nth-child(n + 5),
  .harkenContainer .label:nth-child(n + 5) {
    display: none;
  }

  /*
  * Traditional styles
  */
  .traditionalContainer .point:first-child,
  .traditionalContainer .label:first-child {
    left: 0;
  }

  .traditionalContainer .point:nth-child(2),
  .traditionalContainer .label:nth-child(2) {
    left: 103px;
  }

  .traditionalContainer .point:nth-child(3),
  .traditionalContainer .label:nth-child(3) {
    left: 206px;
  }

  .traditionalContainer .point:nth-child(4),
  .traditionalContainer .label:nth-child(4) {
    left: 309px;
  }

  .traditionalContainer .point:nth-child(5),
  .traditionalContainer .label:nth-child(5) {
    left: 412px;
  }

  .traditionalContainer .point:nth-child(6),
  .traditionalContainer .label:nth-child(6) {
    left: 515px;
  }

  .traditionalContainer .point:nth-child(7),
  .traditionalContainer .label:nth-child(7) {
    display: initial;
    left: 618px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    width: 1110px;
  }

  .label {
    font-size: 12px;
    font-weight: 500;
    margin-left: 14.5px;
    top: 40px;
  }

  .label br {
    display: none;
  }

  .labelNumber {
    font-size: 12px;
  }

  .labelWeek::after {
    content: " ";
  }

  .point {
    border-radius: 14.5px;
    height: 29px;
    top: 0.5px;
    width: 29px;
  }

  .timeline {
    background-image: url("/images/landing/timeline-large.svg");
    width: 1110px;
  }

  /*
  * Harken styles
  */
  .harkenContainer .point:first-child,
  .harkenContainer .label:first-child {
    left: 0;
  }

  .harkenContainer .point:nth-child(2),
  .harkenContainer .label:nth-child(2) {
    left: 103px;
  }

  .harkenContainer .point:nth-child(3),
  .harkenContainer .label:nth-child(3) {
    left: 309px;
  }

  .harkenContainer .point:nth-child(4),
  .harkenContainer .label:nth-child(4) {
    left: 618px;
  }

  .harkenContainer .point:nth-child(5),
  .harkenContainer .label:nth-child(5) {
    display: initial;
    left: 1030px;
  }

  .harkenContainer .point:nth-child(6),
  .harkenContainer .label:nth-child(6) {
    display: none;
  }

  .harkenContainer .point:nth-child(7),
  .harkenContainer .label:nth-child(7) {
    display: none;
  }

  /*
  * Traditional styles
  */
  .traditionalContainer .point:first-child,
  .traditionalContainer .label:first-child {
    left: 0;
  }

  .traditionalContainer .point:nth-child(2),
  .traditionalContainer .label:nth-child(2) {
    left: 103px;
  }

  .traditionalContainer .point:nth-child(3),
  .traditionalContainer .label:nth-child(3) {
    left: 206px;
  }

  .traditionalContainer .point:nth-child(4),
  .traditionalContainer .label:nth-child(4) {
    left: 309px;
  }

  .traditionalContainer .point:nth-child(5),
  .traditionalContainer .label:nth-child(5) {
    left: 412px;
  }

  .traditionalContainer .point:nth-child(6),
  .traditionalContainer .label:nth-child(6) {
    left: 515px;
  }

  .traditionalContainer .point:nth-child(7),
  .traditionalContainer .label:nth-child(7) {
    left: 618px;
  }

  .traditionalContainer .point:nth-child(8),
  .traditionalContainer .label:nth-child(8) {
    display: initial;
    left: 721px;
  }

  .traditionalContainer .point:nth-child(9),
  .traditionalContainer .label:nth-child(9) {
    display: initial;
    left: 824px;
  }

  .traditionalContainer .point:nth-child(10),
  .traditionalContainer .label:nth-child(10) {
    display: initial;
    left: 927px;
  }

  .traditionalContainer .point:nth-child(11),
  .traditionalContainer .label:nth-child(11) {
    display: initial;
    left: 1030px;
  }
}
