.button {
  align-items: center;
  background-color: var(--color-white);
  border-radius: 12px;
  border-style: none;
  box-shadow: 0px 0px 36px rgba(171, 171, 171, 0.3);
  color: var(--color-primary);
  display: flex;
  font-family: var(--work-sans), sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  justify-content: center;
  margin-left: 12px;
  outline: none;
  padding: 12px 16px;
  white-space: nowrap;
  /* Gets rid of a weird flash on mobile browsers https://stackoverflow.com/questions/3516173/ipad-safari-how-to-disable-the-quick-blinking-effect-when-a-link-has-been-hit */
  -webkit-tap-highlight-color: transparent;
}

.buttonSelected {
  background-color: var(--color-highlight);
  color: var(--color-white);
}

.buttonUnselected:hover {
  background-color: var(--color-separatorHover);
  cursor: pointer;
}

.button:first-child {
  margin-left: 24px;
}

.button:last-child {
  margin-right: 24px;
}

.container {
  align-items: center;
  display: flex;
  padding-bottom: 28px;
  padding-top: 28px;
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.container::-webkit-scrollbar {
  display: none;
}

.endDiv {
  height: 24px;
  visibility: hidden;
  flex-basis: 24px;
  flex-shrink: 0;
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 * 
 * Adjust margins.
 */
@media only screen and (min-width: 640px) {
  .button:first-child {
    margin-left: 48px;
  }

  .endDiv {
    height: 48px;
    visibility: hidden;
    flex-basis: 48px;
    flex-shrink: 0;
  }
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 * 
 * At this breakpoint, sizes are increased.
 */
@media only screen and (min-width: 768px) {
  .button {
    font-size: 20px;
    line-height: 24px;
    padding: 22px 36px;
  }
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 * 
 * At this breakpoint, buttons switch to a vertical layout (instead of an h-scroll). 
 */
@media only screen and (min-width: 1024px) {
  .button {
    margin-left: 48px;
    margin-top: 28px;
  }

  .button:first-child {
    margin-top: 0px;
  }

  .container {
    align-items: flex-start;
    flex-direction: column;
    /* So box-shadow doesn't get cut off */
    padding-right: 24px;
  }

  .endDiv {
    display: none;
  }
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 * 
 * At this breakpoint, we just adjust the margins.
 */
@media only screen and (min-width: 1280px) {
  .button {
    margin-left: 120px;
  }

  .button:first-child {
    margin-left: 120px;
  }
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 * 
 * At this breakpoint, we just adjust the margins.
 */
@media only screen and (min-width: 1536px) {
  .button {
    margin-left: 220px;
  }

  .button:first-child {
    margin-left: 220px;
  }
}
