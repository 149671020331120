.betaButton {
  height: 59px;
  margin-top: 32px;
  max-width: 400px;
  width: 100%;
}

.container {
  background-color: var(--color-highlight);
}

.earlyAccess {
  font-family: var(--work-sans), sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-top: 24px;
}

.harkenLogo {
  height: 33px;
  width: 104px;
}

.header {
  color: var(--color-white);
  font-family: var(--dm-sans), sans-serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 42px;
  margin-top: 36px;
}

.headerImage {
  display: block;
  height: auto;
  max-width: 898px;
  width: 100%;
}

.imageContent {
  /* See https://stackoverflow.com/questions/57516373/image-stretching-in-flexbox-in-safari for why this is needed */
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
}

.innerContainer {
  margin: auto;
  max-width: 1600px;
  position: relative;
}

.mainContent {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 0px 24px;
  position: relative;
  /* So that the image content doesn't sit on top and block the email submit button */
  z-index: 1;
}

.menu {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 0px;
  width: 100%;
}

.subheader {
  color: var(--color-white);
  font-family: var(--work-sans), sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  margin-top: 16px;
  max-width: 563px;
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 * 
 * At this breakpoint, we simply increase padding.
 */
@media only screen and (min-width: 640px) {
  .mainContent {
    padding: 0px 48px;
  }

  .menu {
    padding: 24px 48px 0px;
  }
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 * 
 * At this breakpoint, sizes are increased.
 */
@media only screen and (min-width: 768px) {
  .earlyAccess {
    font-size: 16px;
    line-height: 20px;
    margin-top: 42px;
  }

  .harkenLogo {
    height: 51px;
    width: 160px;
  }

  .header {
    font-size: 54px;
    line-height: 68px;
  }

  .menu {
    padding-top: 36px;
  }

  .subheader {
    font-size: 20px;
    line-height: 24px;
    margin-top: 24px;
  }
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 * 
 * At this breakpoint, we position the mainContent and imageContent side-by-side.
 */
@media only screen and (min-width: 1280px) {
  .innerContainer {
    min-height: 702px;
  }

  .mainContent {
    padding: 0px 120px;
    width: 40%;
  }

  .menu {
    padding: 36px 120px 0px;
  }

  .header {
    margin-top: 109px;
  }

  .headerImage {
    bottom: 0px;
    position: absolute;
    right: 0px;
  }
}

/*
 * NOTE: breakpoint values stolen from https://tailwindcss.com/docs/breakpoints
 * 
 * At this breakpoint, we just adjust the padding.
 */
@media only screen and (min-width: 1536px) {
  .mainContent {
    padding: 0px 220px;
  }

  .menu {
    padding: 36px 220px 0px;
  }
}
