.container {
  background-color: var(--color-white);
  border-radius: 24px;
  box-sizing: border-box;
  height: 170px;
  padding: 38px 20px;
  text-align: center;
  width: 280px;
}

.description {
  color: var(--color-secondary);
  font-family: var(--work-sans), sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
}

.title {
  font-family: var(--dm-sans), sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.time {
  color: var(--color-primary);
  font-family: var(--dm-sans), sans-serif;
  font-size: 20px;
  font-weight: 500;
}

/* Matches LandingTimeline responsiveness */
@media only screen and (min-width: 1200px) {
  .container {
    border-radius: 24px;
    height: 210px;
    padding: 42px 30px;
    width: 363px;
  }

  .description {
    font-size: 16px;
    margin-top: 16px;
  }

  .title,
  .time {
    font-size: 28px;
  }
}
