.container {
  background-color: var(--color-lightPurple2);
  box-sizing: border-box;
  margin: auto;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 36px;
  position: relative;
  /* So bg color appears underneath squiggly */
  z-index: 0;
}

.description {
  color: var(--color-secondary);
  margin: auto;
  margin-top: 32px;
  max-width: 698px;
  text-align: center;
}

.descriptionFont {
  font-family: var(--work-sans), sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

.image {
  margin: auto;
  margin-top: 48px;
  max-width: 842px;
  width: 100%;
}

.title {
  margin: auto;
  margin-top: 80px;
  max-width: 648px;
}

@media only screen and (max-width: 900px) {
  .title {
    margin-top: 70px;
  }
}

@media only screen and (max-width: 768px) {
  .description {
    margin-top: 16px;
  }

  .descriptionFont {
    font-size: 16px;
    line-height: 22px;
  }

  .image {
    margin-top: 24px;
    max-width: 400px;
  }

  .title {
    margin-top: 16px;
  }
}