.cardStudyInfo {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
  width: 320px;
  z-index: 4;
}

.container {
  /* So children with position: absolute get positioned relative to this */
  position: relative;
}

.img {
  display: block;
  /* So z-index works */
  position: relative;
  z-index: 3;
}

.examples {
  background-color: var(--color-white);
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -34%);
  z-index: 2;
}

.phoneBg {
  background-color: var(--color-lightPurple);
  bottom: 0px;
  height: 600px;
  left: 30px;
  position: absolute;
  width: 392px;
  z-index: 1;
}
